/* eslint-disable */
export default {
  data () {
    return {
      isAction: false,
      limit: 30,
      listData: []
    }
  },
  mounted () {
    this.$root.$on('rerenderComponent', () => {
      this.reRender++
    })
    this.$root.$on('getAPIValues', data => {
      this.getValuesFromAPI(data.searchText, data.value, data.index)
    })
    this.$root.$on('getModuleOptions', data => {
      console.log('ddddd', data)
      this.getValuesFromModule({ searchText: data.searchText, moduleName: data.moduleName, index: data.index, skip: data.skip, limit: data.limit })
    })
  },
  methods: {
    // Get Values for list (both main &submodules)
    getListOfRecords ({ type = this.MAINLIST, skip = 0, limit = 25, islazyload = false, searchterm = '' }) {
      // let selectedId = type === this.MAINLIST ? this.$route.params.id : this.moduleObj._id
      if (!islazyload) this.listPayloadObj.loading = true
      // let model = this.listPaginateQueryConstruction({ skip, limit, islazyload, searchterm, type })
      // limit = (!this.listPayloadObj.isSubmodule && this.activeFilterView && this.activeFilterView.appliedfilter && this.activeFilterView.appliedfilter.limit) ? this.activeFilterView.appliedfilter.limit : 25
      limit = 25
      let model = this.listPaginateQueryConstruction({ skip, limit, islazyload, searchterm, type })
      // if (this.listPayloadObj.isSubmodule) {
      //   model = this.listPaginateQueryConstruction({ skip, limit, islazyload, searchterm, type })
      // } else {
      //   this.constructFilterList()
      //   model = {
      //     pageViewId: this.activeFilterView._id,
      //     // islistview: false,
      //     ...this.listPaginateQueryConstruction({ skip, limit, islazyload, searchterm, type })
      //   }
      // }
      // if (!this.listPayloadObj.isSubmodule) model = { ...model, ...{ pageViewId: this.activeFilterView._id, islistview: false } }
      this.listPayloadObj.module = this.MODULE
      // let query = {
      //   searchterm,
      //   filters: type === this.MAINLIST ? this.listPayloadObj.filterArray.length ? this.listPayloadObj.filterArray : [] : null,
      //   relations: type !== this.MAINLIST && (this.moduleObj && !this.moduleObj.isChildrenOfTheModule) ? { $match: { 'Relations': { $elemMatch: { '_id': { $eq: this.parentModuleObj.recordId } } } } } : null,
      //   sort: { $sort: { ['Data.name']: sortBy } },
      //   parentid: this.moduleObj && this.moduleObj.isChildrenOfTheModule ? this.parentModuleObj.recordId : undefined,
      //   // facet: { $facet: { data: [{ $skip: ((pageVal - 1) * itemsPerPage) }], count: [{ $count: 'count' }] } },
      //   facet: { $facet: { data: [{ $skip: skip }, { $limit: limit }], count: [{ $count: 'count' }] } }
      // }
      let moduleName = (type === this.MAINLIST) ? this.moduleName : this.moduleObj.name
      this.$api.execute('post', `moduledata/${moduleName}/paginate_filter`, model)
      .then(response => {
        if (response && response.data ) {
          // let { data, total } = response.data
          let { data, hasmorerecords } = response.data
          this.constructPayloadList(data)
            .then((payloadList) => {
              // this.total = total
              this.listPayloadObj.list = [...(islazyload ? this.listPayloadObj.list : []), ...payloadList]
              this.hasMoreRecords = hasmorerecords
            })
          }
      })
      .finally(() => {
        this.listPayloadObj.loading = false
      })
    },
    async getListOfRecordsCount ({ noLoader = false } = {}) { // Get Values for list (both main & submodules)
      let query = {}
      let { search } = this.$formatter.cloneVariable(this.pagination)
      const globalSearchText = (window.localStorage.getItem(`${process.env.VUE_APP_NAME}_globalsearchterm`) || '')
      search = globalSearchText || search
      if (this.listPayloadObj.isSubmodule) {
        query = await this.listPaginateQueryConstruction({ noLoader }, [])
        console.log(query)
      } else {
          const { page, itemsPerPage } = this.$formatter.cloneVariable(this.pagination)
          const pageVal = page || 1
          const calculatedSkip = this.total >= itemsPerPage ? (pageVal - 1) * itemsPerPage : 0
          console.log(this.activeFilterView)
          const { appliedfilter, _id } = this.activeFilterView;
          const appliedFilterConstruct = {
            filters: appliedfilter.filters,
            pageviewid: this.activeFilterView ? _id : null,
            pagination: appliedfilter.pagination,
            sort: appliedfilter.sort,
            sortby: appliedfilter.sortby,
            sortbyfield: appliedfilter.sortbyfield
          };
          query = {
            ...(search && { searchterm: search }),
            pageViewId: this.activeFilterView ? this.activeFilterView._id : null,
            // islistview: this.currentListView === 1 && this.currentModule.enablechildrelation ? true : false,
            islistview: this.currentListView === 1 && this.currentModule.enablechildrelation ? true : false,
            ...appliedFilterConstruct,
            skip: calculatedSkip,
            limit: itemsPerPage
          }
        }
      let moduleName = this.moduleName
     if (this.currentView !== 2) {
        this.$api.execute('post', `moduledata/${moduleName ? moduleName : this.moduleObj.name}/get_total_count`, query)
        .then(async (response) => {
          if (response.data) {
            this.showCount = true
            this.countLoading = false
            let { total } = response.data
            this.total = total
          //   if (total) {
          //     this.total = total
          // }
        }
        })
      }
    },
    listPaginateQueryConstruction ({ skip, limit, islazyload, searchterm, type }) {
      let savedFilterArray = []
      savedFilterArray = type === this.MAINLIST ? this.constructFilterList() : []
      let model = {
        searchterm,
        ...(this.listPayloadObj.isSubmodule ? null : { ispwa: true }),
        ...(this.listPayloadObj.isSubmodule ? null : { pageViewId: this.activeFilterView._id }),
        ...(this.listPayloadObj.isSubmodule ? {relationid: type !== this.MAINLIST && (this.moduleObj && !this.moduleObj.isChildrenOfTheModule) ? this.parentModuleObj.recordId : null} : null),
        // relations: type !== this.MAINLIST && (this.moduleObj && !this.moduleObj.isChildrenOfTheModule) ? { $match: { 'Relations': { $elemMatch: { '_id': { $eq: this.parentModuleObj.recordId } } } } } : null,
        ...(this.listPayloadObj.isSubmodule ? {parentid: this.moduleObj && this.moduleObj.isChildrenOfTheModule ? this.parentModuleObj.recordId : undefined} : null),
        // filters : type === this.MAINLIST ? savedFilterArray.length ? savedFilterArray : [] : null,
        sortbyfield: this.headers[this.listPayloadObj.sortByField].name,
        sortby: this.listPayloadObj.sortBy,
        islistview: false,
        skip: skip,
        limit: limit
      }
      return model
    },
    constructFilterList (filters) {
      this.listOfAllActiveFields = this.$formatter.cloneVariable(this.allActiveFieldsList)
      var filterConditions = filters || this.appliedFilters
      if (filterConditions && filterConditions.length) {
        filterConditions.forEach((condition) => {
          let hasItemIndex = this.listOfAllActiveFields.findIndex((field) => (condition._id) ? (field._id === condition._id) : (field.allValues.module === condition.allValues.module))
          if (hasItemIndex !== -1) {
            this.$set(this.listOfAllActiveFields, hasItemIndex, { ...this.listOfAllActiveFields[hasItemIndex], ...condition })
            // this.listOfAllActiveFields[hasItemIndex] = this.$formatter.cloneVariable(condition)
          }
        })
        let getActiveItems = this.listOfAllActiveFields.filter(x => x.selectBox)
        let conditions = []
        if (getActiveItems.length) {
          getActiveItems.forEach((element) => {
            var value = {}
            for (let i in element) {
              if (!['field', 'selected', 'type', '_id', 'dbRef', 'selectBox', 'text', 'value'].includes(i)) value[i] = element[i]
            }
            conditions.push({ condition: element.condition, field: element._id, fieldtype: element.type, value: JSON.stringify(value), inputValue: element.inputValue, module: element.isSubModule ? element.allValues.module : undefined, element: JSON.stringify(element) })
          })
          return conditions
        } else return []
      } else return []
    },
    constructPayloadList (data) {
      return new Promise(async (resolve) => {
        let payloadList = []
        // let selectedId = this.isMainModule ? this.$route.params.id : this.moduleObj._id
        // let module = this.listOfModules.find(x => x._id === selectedId)
        // this.listOfSubmoduleFieldColumns = module && module.submoduleascolumns ? module.submoduleascolumns.filter(x => x.show_in_list)  : []
        let actualList = this.$formatter.cloneVariable(data)
        for (let [index, item] of actualList.entries()) {
          item.data = this.constructRecordValues(item)
          item = this.constructOtherValues(item)
          payloadList.push({
            _id: item._id,
            ...item.data,
            permissions: item.permissions,
            accesscontrol: item.accesscontrol,
            hasManagePermission: item.hasManagePermission,
            ancestors: item.ancestors,
            masterid: item.masterid,
            showMore: item.showMore,
            profile: item.profile
          })
        }
        resolve(payloadList)
      })
    },
    // constructRelationModuleData (listOfSubmoduleFieldColumns, element) {
    //   let relationModule = {}
    //   for (let i=0; i< listOfSubmoduleFieldColumns.length; i++) {
    //     let submodulename = listOfSubmoduleFieldColumns[i].module.toLowerCase()
    //     if (element && element[submodulename] && element[submodulename].length) {
    //       relationModule[`${submodulename}_name`] = {
    //         value: element[submodulename][0].data.name,
    //         text: element[submodulename][0].data.name,
    //         isShow: false
    //       }
    //       relationModule[`${submodulename}_id`] = element[submodulename][0]._id
    //     }
    //   }
    //   return relationModule
    // },
    constructRecordValues (recordDetails) {
      // let fieldHeaderObj = []
      let customizedFieldValue = {}
      let returnValue = {}
      recordDetails.data = {
        ...recordDetails.data,
        created_at: recordDetails.created_at,
        created_by: recordDetails.created_by,
        modified_at: recordDetails.modified_at,
        modified_by: recordDetails.modified_by
      }
      for (let header of this.headers) {
        customizedFieldValue[header.name] = recordDetails.data[header.name] || '',
        returnValue = this.constructFieldDataBasedOnType(header, this.$formatter.cloneVariable(recordDetails), customizedFieldValue[header.name], header.name)
        customizedFieldValue[header.name] = returnValue
      }
       return customizedFieldValue
    },
    constructOtherValues (item) {
      if (item.accesscontrol && item.accesscontrol.delete) this.recordsHasManagePermissionCount++
      // if (item.item) this.recordsHasManagePermissionCount++
      // item.relationModule = this.constructRelationModuleData(this.listOfSubmoduleFieldColumns, item)
      let profileUrl = ''
      if (item.profileimage) {
        if (item.profileimage.includes('http')) {
          profileUrl = item.profileimage
        } else {
          profileUrl = `${process.env.VUE_APP_IMAGE_URL}${this.userDetails.domain}/module_profiles/${item.profileimage}`
        }
      }
      item.profile = profileUrl ? profileUrl : null
      return item
    },
    constructFieldDataBasedOnType (fieldHeaderObj, recordDetails, customizedValue, property) {
      // Constructing value for type checkbox, select and user
      switch (fieldHeaderObj.type) {
        case 3: // Checkbox
        customizedValue = recordDetails.data[property] ? true : false
        break
        case 4: // Radio
        let value = fieldHeaderObj.default_value.options.find(x => x.value === recordDetails.data[property])
        customizedValue = recordDetails.data[property]
        if (value) customizedValue = value.label
        break
        case 5: // Select
          switch (fieldHeaderObj.default_value.selectType) {
            case 'module':
            case 'default':
              if (typeof recordDetails.data[property] !== 'string' && recordDetails.data[property] && recordDetails.data[property].length) {
                customizedValue = recordDetails.data[property] ? recordDetails.data[property].map(x => x.name).join(',') : ''
                if (recordDetails.data[property]) {
                  let moduleName = this.moduleName || this.moduleObj.name
                  customizedValue = fieldHeaderObj.enableoptiontranslations ? recordDetails.data[property].map(x => this.$t(`${moduleName}_${fieldHeaderObj.name}_option_${x.value}`)).join(',') : recordDetails.data[property].map(x => x.name).join(',')
                } else customizedValue = ''
                if (fieldHeaderObj.default_value.selectType === 'module') {
                  customizedValue = fieldHeaderObj.default_value.is_multiselect ?  recordDetails.data[property].map(x => x.data.name).join(', ') :  recordDetails.data[property][0].data.name
                }
              } else customizedValue = ''
            break
            case 'custom':
              if (fieldHeaderObj.default_value.is_multiselect && recordDetails.data[property] &&  recordDetails.data[property].length) {
                customizedValue = this.$formatter.cloneVariable( recordDetails.data[property].join(','))
              } else {
                customizedValue = fieldHeaderObj.default_value.is_multiselect ? '' : recordDetails.data[property] || ''
              }
            break
          }
          break
        case 6: // Date
          // let date = recordDetails.data[property] ? this.$formatter.fromUtcToLocal(recordDetails.data[property], 'DD.MM.YYYYTHH:mm:ss') : null
          // recordDetails.data[property] = date ? this.$formatter.formatDate(date, '', 'DD.MM.YYYY') : null
          // customizedValue = recordDetails.data[property]

          recordDetails.data[property] = (recordDetails.data[property]) ? this.$formatter.formatDate(recordDetails.data[property], '', this.userDetails.dateformat) : null
          customizedValue = recordDetails.data[property]
          break
        case 15: // User
          let fieldValue = fieldHeaderObj.value === 'created_by' || fieldHeaderObj.value === 'modified_by' ? recordDetails[fieldHeaderObj.value] : recordDetails.data[property]
          if (fieldValue && fieldValue.length > 0) {
            let eValue = fieldValue.map(e => `${e.firstname} ${e.lastname || ''}`).join(',')
            customizedValue = eValue
          } else{
            customizedValue = ''
          }
          break
        case 16: // DateTime
          recordDetails.data[property] = (recordDetails.data[property]) ? this.$formatter.formatDate(recordDetails.data[property], '', `${this.userDetails.dateformat} HH:mm`) : null
          customizedValue = recordDetails.data[property]
          break
      }
      return customizedValue
    },
    // Setting Headers and pagination for list page
    async getFieldsList (moduleId) {
      return new Promise((resolve) => {
        this.headers = []
        const model = {
          moduleid: moduleId,
          sort_by_field: 'Column_Order',
          sort_by: 1,
          isactive_alone: true,
          include_default_fields: true,
          exceptfieldtypes: [9, 11, 10]
        }
        this.$api.execute('post', 'modulefields/filter', model)
          .then(async ({ data }) => {
            if (data && data.length) {
              let listOfAllActiveFields = []
              this.listPayloadObj.listOfSubModuleFieldsActive = []
              if (this.currentModule) {
                let activeSubmoduleAsColumns = this.currentModule.submoduleascolumns && this.currentModule.submoduleascolumns.length > 0 ? this.currentModule.submoduleascolumns : []
                listOfAllActiveFields = this.$formatter.cloneVariable([...data, ...activeSubmoduleAsColumns]) // Including active submodules to fields list
                listOfAllActiveFields.forEach(element => {
                  element.default_value = element.default_value ? JSON.parse(element.default_value) : null
                })
                this.constructFieldsWithDataForFiltersHandler(listOfAllActiveFields)
              }
              let sortedFields = await this.sortTableHeaderBasedOnSelectedView(listOfAllActiveFields)
              // let sortedFields = (!this.listPayloadObj.isSubmodule) ? await this.sortTableHeaderBasedOnSelectedView(listOfAllActiveFields) : listOfAllActiveFields
              this.listPayloadObj.listOfAllActiveFields = this.$formatter.cloneVariable(listOfAllActiveFields)

              this.headers = this.$formatter.cloneVariable(sortedFields.filter(x => x.show_in_list)).splice(0, 4)
              // this.headers.forEach(element => {
              //   element.default_value = element.default_value ? JSON.parse(element.default_value) : null
              // })
              if (this.listPayloadObj) this.listPayloadObj.items = this.$formatter.cloneVariable(sortedFields.filter(x => x.show_in_list)).splice(0, 4)
              resolve(this.headers)
            }
          })
      })
    },
    constructFieldsWithDataForFiltersHandler (listOfAllActiveFields) { // Constructing active fields to do filter actions & table header
      listOfAllActiveFields.forEach(field => {
        if (field.isSubModule) {
          this.listPayloadObj.listOfSubModuleFieldsActive.push(`${field.module.toLowerCase()}_name`)  // Adding option to set submodule as link for redirection to the module
        }
        field.validationCallback = [true]
        field.label = field.label ? field.label.trim() : ''
        field.default_value = field.isSubModule ? { options: [] } : field.default_value // added this options: [] for reactivity
        if (this.listOfAllActiveFields) {
          this.listOfAllActiveFields.push({ // Filter Field Construction
            _id: field._id,
            text: this.$t(field.label),
            value: field.isSubModule ? `${field.module.toLowerCase()}_name` : field.name,
            dbRef: field.isSubModule ? 'name' : field.name ? this.$formatter.stringToProperCase(field.name) : field.label,
            default_value: field.default_value,
            type: field.type, 
            show: false,
            selected: 'is',
            condition: 'is',
            isSignColumn: field.type === 10,
            allValues: field,
            isSubModule: field.isSubModule
          })
        }
      })
      this.allActiveFieldsList = this.$formatter.cloneVariable(this.listOfAllActiveFields)
    },
    async sortTableHeaderBasedOnSelectedView (fields) {
      let pageViewFields = (this.columnSortOrder && this.columnSortOrder.length) ? this.$formatter.cloneVariable(this.columnSortOrder) : null
      let actualModuleFields = this.$formatter.cloneVariable(fields)
      let newArr = []
      if (this.listPayloadObj.isSubmodule) {
        let listOfStoredPageViews = localStorage.getItem(`${process.env.VUE_APP_NAME}_recent_pageview`) ? JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_recent_pageview`)) : null
        let currentSubModulePageView = (listOfStoredPageViews && listOfStoredPageViews.length) ? listOfStoredPageViews.find(x => (x.user_id === this.userDetails._id) && (x.moduleid === this.moduleObj._id)) : null
        await this.$api.execute('get', `pageviews/get_page_views/${this.moduleObj._id}`)
          .then(({ data }) => {
            if (data && data.length) {
              let activeFilterView = (currentSubModulePageView && data.find(x => x._id === currentSubModulePageView._id)) ? data.find(x => x._id === currentSubModulePageView._id) : data[0]
              pageViewFields = this.getStoredPageViewFields(activeFilterView)
            }
            else this.$router.push('/dashboard')
        })
      }
      pageViewFields.forEach(field => {
        if (field._id || field.module) {
          let fieldItem = actualModuleFields.find(x => x._id ? x._id === field._id : x.module === field.module)
          if (fieldItem) {
            fieldItem.show_in_list = field.show_in_list
            newArr.push(fieldItem)
          }
        }
      })
      return newArr
    },
    getStoredPageViewFields (activePageView) {
      if (activePageView) {
        let moduleFields = (activePageView.modulefields) ? JSON.parse(activePageView.modulefields) : []
        let defaultFields = (activePageView.additionalfields && activePageView.additionalfields.submodules) ? JSON.parse(activePageView.additionalfields.submodules) : []
        let subModuleFields = (activePageView.additionalfields && activePageView.additionalfields.defaultfields) ? JSON.parse(activePageView.additionalfields.defaultfields) : []
        return [...moduleFields, ...defaultFields, ...subModuleFields]
      } else return []
    },
    // Setting Forms fields
    getFieldsForModule (fromCalendar) {
      this.fieldsLoading = true
      let list = []
      const model = {
        moduleid: this.moduleObj.moduleId,
        isactive_alone: true,
        include_default_fields: true
      }
      this.$api.execute('post', 'modulefields/filter', model)
        .then((result) => {
          if (result.data) {
            // if (this.$route.params.record_id && (!this.userDetails.isadmin || this.$route.params.name === 'Event')) list = list.filter((item) => item.label !== 'Event_create_for')
            list = (this.userDetails.isadmin) ? result.data : result.data.filter((field) => (field.name !== 'create_for' && field.label !== 'Event_create_for'))
            list = list.sort(function (a, b) {
              return a.tab_order - b.tab_order
            })
            for (let i = 0; i < list.length; i++) {
              list[i].isShow = true
              list[i].default_value = list[i].default_value ? JSON.parse(list[i].default_value) : null
              if (list[i].type === 3 || list[i].type === 4 || list[i].type === 5) {
                if (list[i].default_value.is_multiselect && !this.moduleObj._id) {
                  this.fields[list[i].name] = []
                  if (list[i].default_value.default_value) this.fields[list[i].name].push(list[i].default_value.default_value)
                } else if (list[i].type !== 3 && !this.moduleObj._id) this.fields[list[i].name] = list[i].default_value.default_value
              } else if (!this.moduleObj._id) {
                if (list[i].type === 6 && list[i].default_value && list[i].default_value.setcurrentdate) {
                  this.fields[list[i].name] = this.$formatter.getCurrentDate(this.userDetails.dateformat)
                } else if (list[i].type === 6 && list[i].default_value && list[i].default_value) {
                  this.fields[list[i].name] = this.$formatter.formatDate(list[i].default_value.default_value, 'YYYY-MM-DDTHH:mm:ss', this.userDetails.dateformat)
                } else if (list[i].type === 16 && list[i].default_value && list[i].default_value.setcurrentdate) {
                  this.fields[list[i].name] = this.$formatter.getCurrentDateAndTime(`${this.userDetails.dateformat} HH:mm`)
                  list[i].dateTimePicker = this.$formatter.formatDate( this.fields[list[i].name], `${this.userDetails.dateformat} HH:mm`, 'YYYY-MM-DD')
                  list[i].timePicker = this.$formatter.formatDate(this.fields[list[i].name], `${this.userDetails.dateformat} HH:mm`, 'HH:mm')
                }
                else if (list[i].type === 15 && list[i].default_value && list[i].default_value.setcurrentuserasdefault) {
                  this.fields[list[i].name] = list[i].default_value.is_multiselect ? [this.userDetails._id] : this.userDetails._id
                } else this.fields[list[i].name] = (list[i].default_value && list[i].default_value['default_value']) ? list[i].default_value['default_value'] === '0' ? null : list[i].default_value['default_value'] : null
              }
              // separete panel fields and other fields
              if (list[i].default_value) {
                const getPanelFieldIndex = list.findIndex(x => x._id === list[i].default_value.panelId)
                if (getPanelFieldIndex >= 0) {
                  list[i].isPanelField = true
                  if (list[getPanelFieldIndex].default_value.panelId !== list[getPanelFieldIndex]._id) {
                    if (!list[getPanelFieldIndex].panel_fields) list[getPanelFieldIndex].panel_fields = []
                    list[getPanelFieldIndex].panel_fields.push(Object.assign({ ...list[i] }, { isPanelField: false }))
                  }
                }
              }
            }
            this.listOfFields = list
            // if (this.listOfFields) {
            //   this.listOfFields.forEach((element, index) => {
            //     if (element.default_value && element.default_value.selectType === 'module' && (element.load_all_records || this.booking)) {
            //       this.getValuesFromModule('', element.default_value.selectedModule, index)
            //     }
            //   })
            // }
            if (fromCalendar) this.$root.$emit('listOfFieldsUpdated')
          }
        })
        .finally(() => {
          this.moduleFieldsFinally()
        })
    },
    moduleFieldsFinally () {
      if (this.isAction) {
        this.$root.$emit('rerenderComponent')
        if (this.moduleObj._id) {
          this.getSingleRecordHandler()
        }
      } else {
        if (this.formId) {
          this.getSingleRecordHandler()
        }
      }
      this.fieldsLoading = false
      this.$root.$emit('calendarDateField')
    },
    // Get Module List to load in select with module list items
    getModuleList (item) {
      const result = this.$api.execute('get', `moduledata/${item.default_value.selectedModule}`)
      return result
    },
    // Get text names from supporting lists items (Module View page)
    getValues (item) {
      if (item.default_value && item.default_value.options) {
        if (item.default_value.selectType === 'default') {
          if (item.default_value.is_multiselect) {
            const ids = item ? item.value : []
            const result = item.default_value.options.filter(x => ids.includes(x.value))
            const name = result.map(e => e.label).join(',')
            return name
          } else {
            const result = item.default_value.options.find(x => x.value === item.value)
            if (result) {
              return result.label
            }
          }
        } else if (item.default_value.selectType === 'module') {
          if (item.default_value.is_multiselect) {
            const ids = item ? item.value : []
            var result = item.default_value.options.filter(x => ids.includes(x._id))
            const name = result.map(e => e.data.name).join(',')
            return name
          } else {
            const result = item.default_value.options.find(x => x._id === item.value)
            if (result) {
              return result.data.name
            }
          }
        }
      }
    },
    getUsername (item, data) {
      var name = ''
      if (this.getUsers.length > 0) {
        if (data && data.is_multiselect) {
          const ids = item || []
          const result = this.getUsers.filter(x => ids.includes(x._id))
          name = result.map(e => e.name).join(',')
          return name
        } else {
          const result = this.getUsers.find(x => x._id === item)
          if (result) name = result.name
          return name
        }
      } else return ''
    },
    // Load list with external API
    getValuesFromAPI (serachRef, value, index) {
      let options = []
      this.$api.execute('get', value.apiUrl, { headers: '' }).then(response => {
        if (value.objectName) options = response.data[value.objectName]
        else options = response.data
        const fieldItem = this.listOfFields[index]
        fieldItem.default_value.options = []
        fieldItem.default_value.options = options
        this.$set(this.listOfFields, index, { ...fieldItem })
      })
    },
    // Load Module List items
    getValuesFromModule ({searchText:search, moduleName, index, skip, limit}) {
      return new Promise((resolve, reject) => {
        if (this.listOfFields && this.listOfFields[index]) {
          let fieldItem = this.listOfFields[index]
          let options = []
          if (fieldItem) {
            let query = []
            let currentModule = this.$route.params ? this.$route.params.name : ''
            // query = [ { $match: { $or: [{ [`Data.name`]: { $options: 'i', $regex: (search.target && search.target.value) ? search.target.value : search } }] } } ]
            query = [ { $match: { $or: [{ [`Data.name`]: { $options: 'i', $regex: (search && search.target && search.target.value) ? search.target.value : search } }, { delete: false }] } }, {$skip: skip},  {$limit: limit} ]
            // this Query will be constructed to load contact by account only for sales module
            if (currentModule && currentModule === 'Sale') {
              if (moduleName === 'Contact') {
                query = [ { $match: { $and: [ { [`Data.name`]: { $options: 'i', $regex: search.target.value } }, { Relations: { $elemMatch: { _id: { $eq: this.fields['company'] } } } } ] } } ]
              }
            }
            // this.$api.execute('post', `moduledata/${moduleName}/query`, query).then(response => {
            //   if (response.data.length > 0) {
            //     response.data.forEach(element => {
            //       options.push({ name: element.data.name, _id: element._id, ...element.data })
            //     })
            //     if (fieldItem.default_value && !fieldItem.default_value.options) fieldItem.default_value.options = []
            //     fieldItem.default_value.options = [...fieldItem['default_value'].options, ...options]
            //   }
            // })
            this.$api.execute('post', `moduledata/${moduleName}/query`, query)
            .then(({ data }) => {
              if (data.length > 0) {
                const options = []
                data.forEach((element) => {
                  options.push({ name: element.data.name, _id: element._id, ...element.data })
              })
              if (!fieldItem.default_value) {
                this.$set(fieldItem, 'default_value', {})
                }
                if (!fieldItem.default_value.options) {
                this.$set(fieldItem.default_value, 'options', [])          
              }
              fieldItem.default_value.options = [...fieldItem.default_value.options, ...options]
              fieldItem.hasNoOptionsValues = false
              } else {
                fieldItem.hasNoOptionsValues = true
                this.$set(this.listOfFields, index, { ...fieldItem })
              }
              // options = this.listData
            })
          } else {
            if (!fieldItem.default_value) fieldItem.default_value = {}
            if (!fieldItem.default_value.options) fieldItem.default_value.options = []
            fieldItem.default_value.options = [...fieldItem.default_value.options, ...options]
            // if (fieldItem.default_value && !fieldItem.default_value.options) fieldItem.default_value.options = []
            // else fieldItem.default_value.options = []
          }
          this.$set(this.listOfFields, index, { ...fieldItem })
        }
        resolve() 
      })
     },
    // Search from company API
    loadCompanyData () {
      this.$api.execute('get', 'https://data.brreg.no/enhetsregisteret/api/enheter?navn=' + encodeURIComponent(this.search), { headers: '' }).then(response => {
        this.items = response.data && response.data._embedded ? response.data._embedded.enheter : ''
        this.companyArray = []
        if (this.items) {
          for (let i = 0; i < this.items.length; i++) {
            this.companyArray.push(this.items[i].navn)
          }
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    // Fill Fields from comapny API
    autoFillAddress (name) {
      if (this.companyArray.length > 0 && this.items) {
        for (var i = 0; i < this.items.length; i++) {
          if (this.items[i].navn === name && this.items[i].forretningsadresse) {
            this.fields.billing_street = this.items[i].forretningsadresse.adresse.join()
            this.fields.billing_city = this.items[i].forretningsadresse.poststed
            this.fields.billing_post = this.items[i].forretningsadresse.postnummer
            this.fields.billing_country = this.items[i].forretningsadresse.land
          }
        }
      }
    },
    async getModuleData (moduleName, id, index, property) {
      if (id) {
        if (id && id.length > 0) {
          let ids = id
          let queries = [
            {
              $project: {
                _id: {
                  $toString: '$_id'
                },
                [`Data.name`]: 1
              }
            },
            { $match: { _id: { $in: ids } } 
          }]
          await this.$api.execute('post', `moduledata/${moduleName}/query`, queries).then(response => {
            if(response && response.data.length > 0) {
              response.data.forEach(element => {
                this[property][index].default_value.options.push({ name: element.data.name, _id: element._id })
              })
            }
          })
        }
      }
    },
    // Get single record for all form
    getRecordHandler (url, type, accessControl) {
      this.showFormLoader = true
      this.$api.execute('get', url).then(response => {
        if (response && response.data) {
          if (response.data.profileimage) {
            if (response.data.profileimage.includes('http')) {
              this.moduleObj.profileimage = response.data.profileimage
            } else {
              this.moduleObj.profileimage = `${process.env.VUE_APP_IMAGE_URL}${this.userDetails.domain}/module_profiles/${response.data.profileimage}`
            }
        }
          response.data.data.relations = []
          response.data.data.permissions = response.data.permissions
          response.data.data.relations = response.data.relations
          this.fields = response.data.data
          if (type !== 'calendar') {
            if (response.data.accesscontrol && response.data.accesscontrol.edit) this.customizeEditValues(response) // Check record permission on edit (Only on module edit)
            else this.$router.push('/dashboard') 
          } else this.customizeEditValues(response)
          this.reRender++
        }
        this.showFormLoader = false
        this.$emit('subModuleRecordAccess', response.data.accesscontrol)
      })
    },
    customizeEditValues (response) {
      this.listOfFields.forEach((element, index) => {
        switch (element.type) {
          case 5:
            switch (element.default_value.selectType) {
              case 'default':
                if (response.data.data[element.name] && response.data.data[element.name].length) {
                  if (element.default_value.is_multiselect)  response.data.data[element.name] = response.data.data[element.name].map(x => x.value)
                  else response.data.data[element.name] = response.data.data[element.name][0].value
                }
                break
              case 'module':
                if (response.data.data[element.name] && response.data.data[element.name].length && !this.isBooking) {
                  if (element.default_value.is_multiselect) {
                    response.data.data[element.name].forEach(moduleValue => {
                      this.listOfFields[index].default_value.options.push({ _id: moduleValue._id, name: moduleValue.data.name })
                    })
                    response.data.data[element.name] = response.data.data[element.name].map(x => x._id)
                  } else {
                    if (!this.listOfFields[index].default_value.options) this.listOfFields[index].default_value.options = []
                    this.listOfFields[index].default_value.options.push({ _id: response.data.data[element.name][0]._id, name: response.data.data[element.name][0].data ? response.data.data[element.name][0].data.name : '' })
                    response.data.data[element.name] = response.data.data[element.name][0]._id
                  }
                }
              break
              case 'custom':
                element.customOptions = []
                element.optionName = 'name'
                element.optionValue = 'name'
                let value = this.$formatter.cloneVariable(response.data.data[element.name])
                if (value) {
                  if (element.default_value.is_multiselect) {
                    value.forEach(item => {
                      element.customOptions.push({ name: item })
                    })
                  } else {
                    element.customOptions.push({ name: value })
                  }
                }
                response.data.data[element.name] = element.default_value.is_multiselect ? response.data.data[element.name] && response.data.data[element.name].length ? response.data.data[element.name] : '' : response.data.data[element.name]
            }
            break
          case 6:
            if (response.data.data[element.name]) {
              response.data.data[element.name] = (response.data.data[element.name]) ? this.$formatter.formatDate(response.data.data[element.name], '', this.userDetails.dateformat) : null
              element.date_value = this.$formatter.formatDate(response.data.data[element.name], this.userDetails.dateformat, 'YYYY-MM-DD')
            } else element.date_value = response.data.data[element.name] = null
            break
          // case 10:
          //   setTimeout(() => {
          //     if (this.$refs.formReferences && this.$refs.formReferences.$refs[`signaturePad_${index}`] && this.$refs.formReferences.$refs[`signaturePad_${index}`].length) {
          //       this.$refs.formReferences.$refs[`signaturePad_${index}`][0].fromDataURL( response.data.data[element.name])
          //     }
          //   }, 300)
          //   break
          case 10:
            setTimeout(() => {
              if (this.$refs.formReferences && this.$refs.formReferences.$refs[`signaturePad_${element._id}`] && this.$refs.formReferences.$refs[`signaturePad_${element._id}`].length) {
                this.$refs.formReferences.$refs[`signaturePad_${element._id}`][0].fromDataURL(response.data.data[element.name])
              }
              if (this.$refs.formReferences && this.$refs.formReferences.$refs.panelFormReference) {
                this.$refs.formReferences.$refs.panelFormReference.forEach((x) => {
                  if ((x.$refs[`signaturePad_${element._id}`] && x.$refs[`signaturePad_${element._id}`].length)) {
                    x.$refs[`signaturePad_${element._id}`][0].fromDataURL(response.data.data[element.name])
                  }
                })
              }
            }, 500)
            break
          case 15:
            if (response.data.data[element.name] && response.data.data[element.name].length) {
              if (element.default_value.is_multiselect)  response.data.data[element.name] =  response.data.data[element.name].map(x => x._id)
              else  response.data.data[element.name] =  response.data.data[element.name][0]._id
            }
            break
          case 16:
            if (response.data.data[element.name]) {
              // const date = this.$formatter.fromUtcToLocal(response.data.data[element.name], 'DD.MM.YYYYTHH:mm:ss')
              response.data.data[element.name] = this.$formatter.formatDate(response.data.data[element.name], '', `${this.userDetails.dateformat} HH:mm`)
              element.dateTimePicker = this.$formatter.formatDate(response.data.data[element.name], `${this.userDetails.dateformat} HH:mm`, 'YYYY-MM-DD')
              element.timePicker = this.$formatter.formatDate(response.data.data[element.name], `${this.userDetails.dateformat} HH:mm`, 'HH:mm')
            } else {
               response.data.data[element.name] = element.dateTimePicker = element.timePicker = null
            }
            break
          case 17:
            if (response.data.data[element.name]) {
              var oldAttachments = this.$formatter.cloneVariable( response.data.data[element.name])
              response.data.data[element.name] = null
              fieldValues[`${element.name} oldAttachments`] = JSON.parse(oldAttachments)
            }
            break
        }
      })
      // this.fields = Object.assign(this.fields, response.data.data)
    },
    reconstructModuleFieldValues (fromBooking) {
      let model = this.$formatter.cloneVariable(this.fields)
      this.listOfFields.forEach((element, index) => {
        if (element.type === 5 || element.type === 15) {
          if (!fromBooking) {
            if (this.moduleName === 'Account' && element.name === 'name') {
              model[element.name] = model[element.name] ?  typeof model[element.name] === 'object'  ? model[element.name].navn : model[element.name] : null
            }
            if(model[element.name] === '0') model[element.name] = null
          }
        }
        // if (element.type === 10) {
        //   let { isEmpty, data } = this.$refs.formReferences.$refs[`signaturePad_${index}`][0].saveSignature()
        //   if (!isEmpty) { 
        //     model[element.name] = data 
        //   } else {
        //     model[element.name] = ''
        //   }
        // }
        if (element.type === 10) {
          if (this.$refs.formReferences.$refs[`signaturePad_${element._id}`] && this.$refs.formReferences.$refs[`signaturePad_${element._id}`].length) {
            let isEmpty = null
            let data = null 
            let signatureData = this.$refs.formReferences.$refs[`signaturePad_${element._id}`][0].saveSignature()
            isEmpty = signatureData.isEmpty
            data = signatureData.data
          if (!isEmpty) { 
            model[element.name] = data 
          } else {
            model[element.name] = ''
          }
        }
          if (this.$refs.formReferences.$refs.panelFormReference) {
            this.$refs.formReferences.$refs.panelFormReference.forEach((x) => {
              if ((x.$refs[`signaturePad_${element._id}`] && x.$refs[`signaturePad_${element._id}`].length)) {
                let signatureData = x.$refs[`signaturePad_${element._id}`][0].saveSignature()
                let data = null
                data = signatureData.data
                model[element.name] = data
              }
            })
        }
        }
        if (element.type === 6) {
          model[element.name] = model[element.name] ? this.$formatter.formatDate(model[element.name], this.userDetails.dateformat, 'YYYY-MM-DDTHH:mm:ss') : null
        }
        if (element.type === 13) {
          model[element.name] = model[element.name] ? parseFloat(model[element.name]) : 0
        }
        if (element.type === 16) {
          model[element.name] = model[element.name] ? this.$formatter.formatDate(model[element.name], `${this.userDetails.dateformat} HH:mm`, 'YYYY-MM-DDTHH:mm:ss') : null
        }
        if (element.type === 17) {
          model[element.name] = this.fields[element.name] 
        }
      })
      return model
    },
    // Update Single Record Handler
    updateRecordHandler ({ id, moduleName, moduleId, type, from = null } = {}) {
      if (this.$refs.formReferences.$refs.validateForm.validate()) {
        this.loading = true
        let model = this.reconstructModuleFieldValues(false)
        const url = id ? `${this.MODULE_URL}/${moduleName}/${id}` : `${this.MODULE_URL}/${moduleName}${(this.userDetails.isadmin && moduleName === 'Event') ? `?event_for=${model.create_for}` : ''}`
        let relations = model.relations
        let permissions = model.permissions && model.permissions.length > 0 ? model.permissions : null
        model.relations = undefined
        if (this.moduleObj.profileimage) {
          if (this.moduleObj.profileimage.includes('http')) {
            this.moduleObj.profileimage = this.moduleObj.profileimage
          } else {
            this.moduleObj.profileimage = `${process.env.VUE_APP_IMAGE_URL}${this.userDetails.domain}/module_profiles/${this.moduleObj.profileimage}`
          }
      }
        let modelJson = {
          data: model,
          relations,
          permissions,
          profileimage: this.moduleObj.profileimage ? this.moduleObj.profileimage : '',
          _id: id || undefined
        }
        this.$api.execute(id ? 'put' : 'post', url, modelJson)
          .then((response) => {
            if (from === 'calendar') {
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: id ? 'updatedSuccess' : 'addedSucess' })
              this.closeDialogHandler()
              this.getEventsAndTasks()
            } else {
              this.moduleObj._id = response.data._id
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: type === 'UPDATE' ? 'updatedSuccess' : 'addedSucess' })
              if (type && type !== 'UPDATE') this.$router.push(`/module/${moduleName}/${moduleId}/view/${response.data._id}`)
            }
          })
          .finally(() => {
            this.$store.dispatch('getModuleList')
            this.loading = false
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    checkRecordPermission (model) {
      // this.customizeEditValues(model) //Just for Calendar date format issue while editing the record
      if (this.moduleObj._id) {
        if (this.fields.permissions && this.fields.permissions.length > 0) {
          let userPermission = this.fields.permissions.find(x => x.user_id === this.userDetails._id)
          this.customizeEditValues(model)
          let hasData = this.fields.permissions.filter(x => this.userDetails.groups.includes(x.group_id))
          if (this.userDetails.isadmin || this.userDetails.iscontentadmin) {
            this.hasManagePermission = true
            this.showPageLoader = false
          } else if (userPermission) {
            if (userPermission.access_level === 'manage') {
              this.hasManagePermission = true
            } else this.hasManagePermission = false
          } else if (hasData) {
            let permission = hasData.find(x => x.access_level === this.MANAGE)
            if (permission) this.hasManagePermission = true
            else this.$router.push('/dashboard')
            this.showPageLoader = false
          } else this.$router.push('/dashboard')
        }
        else this.customizeEditValues(model)
      }
      // let obj = this.$formatter.cloneVariable(this.moduleObj)
      // if (this.userDetails.isadmin || this.userDetails.iscontentadmin) this.moduleObj.hasPermissionTo = this.MANAGE
      // else {
      //   if (obj.permissions && obj.permissions.length > 0) {
      //     let userPermission = obj.permissions.find(x => x.user_id === this.userDetails._id) // Check User permission
      //     if (userPermission) {
      //       if (userPermission) this.moduleObj.hasPermissionTo = userPermission.access_level
      //     } else { // Check Group permission
      //       let found = []
      //       this.userDetails.groups.forEach(item => {
      //         found = [...found, ...obj.permissions.filter(x => x.group_id === item)]
      //       })
      //       if (found && found.length > 0) {
      //         let permission = found.find(x => x.access_level === 'manage')
      //         if (permission) this.moduleObj.hasPermissionTo = permission.access_level
      //         else  {
      //           this.moduleObj.hasPermissionTo = this.VIEW
      //         }
      //       } else {
      //         this.$router.push('/notavailable')
      //       }
      //     }
      //   } else this.moduleObj.hasPermissionTo = this.VIEW
      // }
    },
    setCurrentPageViewInLocalStorage (item) {
      let listOfStoredPageViews = localStorage.getItem(`${process.env.VUE_APP_NAME}_recent_pageview`) ? JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_recent_pageview`)) : []
      let currentViewIndex = listOfStoredPageViews.findIndex(x => (x.user_id === this.userDetails._id) && (x.moduleid === this.currentModule._id))
      if (currentViewIndex >= 0) listOfStoredPageViews[currentViewIndex] = { _id: item._id, name: item.name, moduleid: item.moduleid, user_id: this.userDetails._id }
      else listOfStoredPageViews.push({ _id: item._id, name: item.name, moduleid: item.moduleid, user_id: this.userDetails._id })
      localStorage.setItem(`${process.env.VUE_APP_NAME}_recent_pageview`, JSON.stringify(listOfStoredPageViews))
    },
    saveFilterViewHandler () {
      if (this.$refs.saveFilterView.validate()) {
        this.listPayloadObj.loading = true
        this.filterLoader = true
        let model = this.$formatter.cloneVariable(this.filterBasedView)
        model.appliedfilter = this.activeFilterView.appliedfilter
        model.modulefields = this.activeFilterView.modulefields
        model.moduleId = this.currentModule._id
        this.$api.execute('post', 'pageviews', model)
          .then(({ data }) => {
            this.setCurrentPageViewInLocalStorage(data)
            this.listOfFilterViews.push(data)
            this.activeFilterView = this.$formatter.cloneVariable(data)
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'saveSuccess' })
            this.updatePageView({})
          })
          .finally(() => {
            this.dialog = false
            this.filterLoader = false
          })
      }
    },
    getCurrentPageViewFromLocalStorage (views) {
      let listOfStoredPageViews = localStorage.getItem(`${process.env.VUE_APP_NAME}_recent_pageview`) ? JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_recent_pageview`)) : []
      let currentview = views[0]
      if (listOfStoredPageViews && listOfStoredPageViews.length) {
        let currentModulePageView = listOfStoredPageViews.find(x => (x.user_id === this.userDetails._id) && (x.moduleid === this.currentModule._id))
        currentview = (currentModulePageView && views.find(x => x._id === currentModulePageView._id)) ? views.find(x => x._id === currentModulePageView._id) : views[0]
      }
      return currentview
    },
    getAllFilterViews () {
      return new Promise((resolve) => {
        this.$api.execute('get', `pageviews/get_page_views/${this.moduleId}`)
          .then(({ data }) => {
            if (data && data.length) {
              let views = this.$formatter.cloneVariable(data)
              // let activeFilterView = views.find(x => x.isdefault) ? this.$formatter.cloneVariable(views.find(x => x.isdefault)) : this.$formatter.cloneVariable(views[0])
              let activeFilterView = this.$formatter.cloneVariable(this.getCurrentPageViewFromLocalStorage(views))
              this.listOfFilterViews = this.$formatter.cloneVariable(views)
              // this.columnSortOrder = (activeFilterView && activeFilterView.modulefields) ? JSON.parse(activeFilterView.modulefields) : []
              this.columnSortOrder = this.getStoredPageViewFields(activeFilterView)
              this.activeFilterView = activeFilterView
              if (activeFilterView.appliedfilter && activeFilterView.appliedfilter.filters && activeFilterView.appliedfilter.filters.length) {
                this.appliedFilters = activeFilterView.appliedfilter.filters.map(x => {
                  return JSON.parse(x.element)
                })
              } else this.appliedFilters = []
              this.setPaginationForFilterView(JSON.parse(activeFilterView.appliedfilter.pagination))
              resolve()
            } else {
              this.$router.push('dashboard')
            }
          })
      })
    }
  }
}
